<template>
	<div id="TradeArea">
		<MyHeader />
		<div class="block1" id="AboutMoona">
			<h3>Learn More About Moona</h3>
			<p>Since its establishment in 1988, Moona has grown to be one of the leading manufacturer
				and exporter of wood shutters & shutter components in China.Moona Shutter is a number of Moona Group,
				which has four parts: Hengda Wood, Sennan Wood, Moona Shutter, Moona Furniture,
				 owning factories about 165,000M2 and construction lands 92,000M2 and 1000 workers.With premium quality,
				innovation, and sustainability our shutters and shutter components got welcomed in Europe, North America,
				Australia and many other areas.</p>
			<div class="custom_img">
				<img src="@/assets/images/trade-01.png" />
				<img src="@/assets/images/trade-02.png" />
			</div>
		</div>
		<div class="block1">
			<h3>Location Advantage</h3>
			<p>Located in Cao County, the biggest paulownia processing base in China,
				 where gather assorted related industries and it has become a complete industrial
				chain which allows us to have adequate supply of raw materials and enhance a powerful technical
				fleet to control quality. We have perennial raw material inventory of 5000 M3, which can run
				3 months-nonstop production. </p>
			<div class="custom_img">
				<img src="@/assets/images/trade-03.png" />
				<img src="@/assets/images/trade-04.png" />
			</div>
		</div>
		<div class="block1 latest">
			<h3>Quality & Manufacturing</h3>
			<p>All of the wood are twice-kiln-dried to keep batter stability, which is unique and advanced in the
				industry. From the raw material processing to the packaging every step will go through very strict
				quality control system, process quality control and final inspection are carefully followed to ensure
				that every shutter leaves the factory in immaculate condition.</p>
			<div class="custom_img">
				<img src="@/assets/images/trade-11.png" />
				<img src="@/assets/images/trade-12.png" />
				<img src="@/assets/images/trade-13.png" />
				<img src="@/assets/images/trade-14.png" />
				<img src="@/assets/images/trade-15.png" />
				<img src="@/assets/images/trade-16.png" />
				<img src="@/assets/images/trade-17.png" />
				<img src="@/assets/images/trade-05.png" />
			</div>
		</div>
		<MyFooter />
	</div>
</template>

<script>
	import MyHeader from '@/components/MyHeader.vue'
	import MyFooter from '@/components/MyFooter.vue'
	export default {
		name: 'TradeArea',
		components: {
			MyHeader,
			MyFooter
		},
		mounted() {
			let _hashName = this.$route.query.hashName
			if (_hashName) {
				var inter = setInterval(() => {
					let target = document.querySelector(`#${_hashName}`)
					if (target) {
						clearInterval(inter)
						target.scrollIntoView()
					}
				}, 0)
			}
		}
	}
</script>
<style lang="less" scoped>
	#TradeArea {
		.block1 {
			width: 1366px;
			margin: 0 auto;

			h3 {
				width: 920px;
				font-size: 58px;
				margin: 100px auto 0;
				font-family: STYuanti-SC-Bold;
				line-height: 92px;
				color: #1e1e1e;
				text-align: center;
				font-weight: normal;
			}

			p {
				width: 1366px;
				margin: 0 auto;
				font-size: 25px;
				// font-size: 36px;
				font-family: DINNextLTPro-Regular;
				line-height: 59px;
				color: #626262;
			}

			.custom_img {
				width: 1366px;
				margin: 0 auto;
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-wrap: wrap;

				// margin-bottom: 100px;
				img {
					width: 630px;
					height: 417px;
					margin-bottom: 20px;
				}
			}

			&.latest {
				margin-bottom: 120px;
			}
		}
	}
</style>
<style lang="less">
	#TradeArea {
		.el-form-item__error {
			top: 78px;
		}

		.el-input__inner {
			height: 100%;
			line-height: 100%;
			background-color: transparent;
		}

		.el-textarea__inner {
			min-height: 104px !important;
		}

		.el-input__inner,
		.el-textarea__inner {
			background-color: transparent;
			outline: none;
			box-shadow: none;
		}
	}
</style>
